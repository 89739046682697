import React from "react";
import Footer from '../footer/Footer'
import "./blog.css";

const Blog = () => {
  return (
    <>
      <main className="container">
        <h2>Blog</h2>
        <p>Welcome to my blog! Stay tuned for updates.</p>
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default Blog;
